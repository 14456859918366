import { GDPRPopup } from './domains/App/components'
import { GoogleOAuthProvider } from '@react-oauth/google'
import Navigator from './pages/Navigator'
import { TranslationsProvider } from 'contexts/Translation'
/**
 * It returns a React component that renders a TranslationsProvider and a Navigator.
 * @returns The <TranslationsProvider> component.
 */
function App() {
  return (
    <>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_OAUTH_CLIENT_ID}>
        <TranslationsProvider>
          <Navigator />
          <GDPRPopup />
        </TranslationsProvider>
      </GoogleOAuthProvider>
    </>
  )
}

export default App
